
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";

export default defineComponent({
    name: "redemptions",
    components: {
    },
    data(){
        return {
            data: [],
            page: 1,
            pages: 1,
            count: 0
        }
    },
    methods: {
      getTransactions(){

        this.axios.get(`/history`).then(res => {
            let transactions = res.data.history
            if(this.$route.query.id != null){
              transactions = transactions.filter(transaction=>transaction.recipientEmail == this.$route.query.id)
            }

            this.data = transactions.reverse()
            this.count = res.data.total
        }).catch(e=>{
          try{
            if(e.response.status == 401){
              useToast().warning('Your session has expired, please login')
            }else{
              useToast().error(e.response.data.message)
            }
          }catch(err){
            useToast().warning(e.message)
          }
        })
      },
      paginate(i){
        this.page = i
        this.getTransactions()
      }
    },
    mounted(){
      setCurrentPageTitle("Redemptions")
      this.getTransactions()
    }
});
